export default function () {
  $(document).bind("load", () => $("*[data-modal-content]").hide());

  $(document).on("click", "*[data-modal]", function (event) {
    if (!$("body").hasClass("mobile-true")) {
      event.preventDefault();

      return modal.show({ element: $(this) });
    }
  });

  var modal = {
    defaults: { offset: 100 },
    elements: {},

    create_elements() {
      if ($("#modal").length === 0) {
        $("body").append(
          '<div id="modal"><div class="close"></div><main><section><header><h3></h3></header><div class="content"></div></section></main><div class="nav prev"><div></div></div><div class="nav next"><div></div></div></div>'
        );

        $("#overlay, #modal div.close").click(() => modal.hide());

        $("#modal div.prev").click(() => modal.prev());

        $("#modal div.next").click(() => modal.next());
      }

      return $("#modal");
    },

    show(options) {
      this.create_elements();
      this.hide();

      this.elements.parent = (this.elements.element = options.element).parents(
        "*[data-modal-container]"
      );

      $("#modal").attr("class", "");
      $("#modal").attr("data-name", options.element.attr("data-modal-name"));

      $("#modal h3").text(
        this.elements.parent.find("*[data-modal-title]").text()
      );
      $("#modal .content").html(
        this.elements.parent.find("*[data-modal-content]").html()
      );

      $("body").addClass("modal").addClass("overlay");
      this.elements.parent.addClass("modal-active");

      $("#modal div.nav").hide();

      if (
        (this.elements.prev = this.elements.parent.prev(
          "*[data-modal-container]"
        )).get(0)
      ) {
        $("#modal div.prev")
          .show()
          .find("div")
          .text(this.elements.prev.find("*[data-modal-title]").text());
      }

      if (
        (this.elements.next = this.elements.parent.next(
          "*[data-modal-container]"
        )).get(0)
      ) {
        $("#modal div.next")
          .show()
          .find("div")
          .text(this.elements.next.find("*[data-modal-title]").text());
      }

      if ($("#modal").height() > $(window).height() - 200) {
        $("#modal").addClass("large");
      }

      $("#modal").css("margin-top", -($("#modal").height() / 2));

      $("#modal").show();

      return setTimeout(() => $("#modal").addClass("ready"), 750);
    },

    hide() {
      $("body").removeClass("modal").removeClass("overlay");
      $(".modal-active").removeClass("modal-active");

      $("#modal").css("margin-top", 0);
      return $("#modal").hide();
    },

    prev() {
      return this.show({ element: this.elements.prev.find("*[data-modal]") });
    },

    next() {
      return this.show({ element: this.elements.next.find("*[data-modal]") });
    },
  };
}
