// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
//import Turbolinks from "turbolinks";

Rails.start();
//Turbolinks.start();

import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import Modal from "../libraries/modal";
import Layout from "../libraries/layout";

Modal();
Layout();
