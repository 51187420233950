export default function () {
  let $event_for_click = "click";

  $(document).ready(function () {
    $(this).trigger("load");

    if ($("body").hasClass("mobile-true")) {
      $event_for_click = "touchstart";
    }

    return $(this).on($event_for_click, "nav.main .mobile", () =>
      $("body").toggleClass("mobile-menu overlay")
    );
  });

  $(document).on("page:load", () => $(document).trigger("load"));

  $(document).on("page:change", function () {
    if ($("body").hasClass("mobile-menu")) {
      return $("body").attr("class", "");
    }
  });

  $(window).on("scroll", function () {
    if ($(window).scrollTop() > 75) {
      //$('nav.main').height()
      $("nav.main").addClass("scrolled");
    } else {
      $("nav.main").removeClass("scrolled");
    }

    $("*[data-anchor-name]").each(function () {
      const $next = $(this).next("*[data-anchor-name]").first();
      if (
        $(window).scrollTop() >=
          $(this).offset().top - $("nav.main").height() &&
        (!$next.get(0) || $(window).scrollTop() <= $next.offset().top)
      ) {
        $("div.mobile-menu ul li.active, nav.main ul li.active").removeClass(
          "active"
        );
        return $(
          "div.mobile-menu ul li." +
            $(this).attr("class").split(" ")[0] +
            ", nav.main ul li." +
            $(this).attr("class").split(" ")[0]
        ).addClass("active");
      }
    });

    return $("main section").each(function () {
      const $next = $(this).next("section").first();
      if (!$(this).hasClass("active")) {
        $(this).addClass("active");

        if ($(this).find(".odometer").length > 0) {
          return $(this)
            .find(".odometer")
            .each(function () {
              return $(this).text($(this).data("number"));
            });
        }
      }
    });
  });
}
